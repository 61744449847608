<template>
<div>
   
  <div class="card" v-if="notes">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcıya tanımlanan notlar</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{notes.length}}</b> adet not</span>
      </h3>
    </div>
    <!--end::Header-->
      <div class="card-body">
        <a v-if="isAdmin" href="#new_package" data-bs-target="#new_package" class="btn btn-success btn-sm mb-10" data-bs-toggle="modal">Yeni paket tanımla</a>
        
        <TicketNotesList :notes="notes"  />   
      </div>
     
  </div>
</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import TicketNotesList from '@/components/modals/forms/TicketNotesList.vue'
import api from '@/core/services/ApiService'

export default {
  
  name: "Gorusmeler",
  props:['profile'],
  components:{TicketNotesList},
  data(){
      return {
          notes:[],
      }
  },
  
  

  methods:{

      getNotes(){
         if(this.profile){
             api.get('/User/ListUserNotes/UserBO/'+this.profile.userId).then((res)=>{
                this.notes = res.data;
                console.log(res.data);
                
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Aktiviteler", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);
      this.getNotes();
    
  }
  
}
</script>
