<template>
  <div>
    <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
      <thead>
          <tr class="fw-bolder">
              <th>Danışman</th>
              <th>Danışan</th>
              <th>Görüşme Türü</th>
              <th>Not</th>
              <th>Tarih</th>
              <th>İşlem</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="(item, i) in notes" :key="i">
              <td>{{item.consultantName+" "+item.consultantSurname}}</td>                       
              <td>{{item.patientName+" "+item.patientSurname}}</td>                       
              <td>{{ticketTypes[item.ticketTypeId]}}</td>                       
              <td>{{item.note}}</td>                       
              <td>{{moment(item.createdDate).format('DD MMMM YYYY HH:mm:ss')}}</td>
              <td>
                <div v-if="isAdmin">
                <a class="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#edit-note" @click.prevent="editNote(item)" href="">Düzenle</a> <a @click.prevent="DeleteUserNote(item)" class="btn btn-sm btn-danger" href="">Sil</a>
                </div>
              </td>
          </tr>
      </tbody>
    </table>

 <div class="modal" tabindex="-1" id="edit-note">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Not Güncelleme</h5>
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
            <span class="svg-icon svg-icon-2x"></span>
          </div>
        </div>

        <div class="modal-body">
          <div v-if="selectedNote">
           
            <div class="row mb-4 border-bottom pb-4">
              <div class="col-lg-2 fw-bolder">Danışman</div>
              <div class="col-lg-3">{{selectedNote.consultantName}} {{selectedNote.consultantSurname}}</div>
            </div>
            <div class="row mb-4 border-bottom pb-4">
              <div class="col-lg-2 fw-bolder">Görüşme Türü</div>
              <div class="col-lg-3">{{ticketTypes[selectedNote.ticketTypeId]}}</div>
            </div>
            <div class="row mb-4 border-bottom pb-4">
              <div class="col-lg-2 fw-bolder">Tarih</div>
              <div class="col-lg-3">{{moment(selectedNote.createdDate).format('DD MMMM YYYY dddd, HH:mm:ss')}}</div>
            </div>
            <div class="row mb-4 border-bottom pb-4">
              <div class="col-lg-2 fw-bolder">Ticket ID</div>
              <div class="col-lg-4">{{selectedNote.ticketId}}</div>
            </div>
            <div class="row">
                <textarea name="" v-model="noteModel.note" id="" cols="30" class="form-control" rows="5"></textarea>
            </div>
            

          </div>
        </div>

        <div class="modal-footer">

          <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">Kapat</button>
          <button class="btn btn-success" @click.prevent="UpdateUserNote()">Kaydet</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import moment from 'moment'
import Swal from 'sweetalert2'
import api from '@/core/services/ApiService'
import JwtService from '@/core/services/JwtService'

export default {
    name:'TicketNotesList',
    props:['notes'],
    data(){
        return {
            isAdmin:false,
            selectedNote:null,
            noteModel:{},
            ticketTypes:{
                0:'Tümü',
                1: 'Hemen Görüşme',
                10: 'Randevulu Görüşme'
            },
        }
    },

    methods:{
        moment(date){
            return moment(date);
        },

        UpdateUserNote(){
        api.post('/User/UpdateUserNote',this.noteModel).then((res)=>{
          Swal.fire({
            title:'Bilgi',
            icon:'success',
            text:'Not güncellendi.',
          }).then(()=>{
            document.location.reload();
          })
          }).catch((err)=>{
            alert("hata");
        })
      },
      DeleteUserNote(item){
        Swal.fire({
          title:'Emin misiniz?',
          icon:'warning',
          text:'Bu kullanıcı notunu silmek istediğinize emin misiniz? Bu işlemin geri dönüşü yoktur',
          showCancelButton:true,
          cancelButtonText:'Vazgeç',
        }).then((res)=>{
          if(res.isConfirmed){
            api.get('/User/DeleteUserNote/'+item.noteId).then((res)=>{
              Swal.fire({
                title:'Bilgi',
                icon:'success',
                text:'Not silindi.',
              }).then(()=>{
                document.location.reload();
              });
            })
          }
        });
      },

      editNote(item){
        this.selectedNote = item;
        this.noteModel.noteId=item.noteId;
        this.noteModel.note=item.note;
        this.noteModel.userId=item.patientId;
      },
    




    },

    mounted(){
      this.isAdmin = JwtService.isAdmin();
    }
}
</script>

<style>

</style>